import { Button, useStyles, Flex } from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import * as S from './FeaturedItems.style';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { useSaveToDesignBoard } from '~/modules/design-board/hook/use-save-to-design-board';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import PageSection from '@modules/professional/component/page-layout/PageSection';
import type { FeaturedProjectsProps } from './FeaturedProjects';
import ProjectTile from '~/modules/tile/component/project-tile/ProjectImpressionTile';

export const FeaturedProjectsMobile = ({
    projects,
    openInNewTab,
    disableTileLink,
    disableSaveToDesignBoard,
}: FeaturedProjectsProps) => {
    const professional = useProfessionalInitialRouteData();
    const { featuredProjectsList, projectCount } = projects;

    const renderTileAs = disableTileLink ? 'div' : Link;

    const { favouriteItem } = useSaveToDesignBoard('ProjectPin');

    const { css } = useStyles();
    if (!featuredProjectsList?.length) return null;

    const projectTiles = featuredProjectsList.map((n) => {
        const productInfo = {
            images: n.images,
            projectLink: n.link,
            professionalName: professional.Title,
            projectName: n.title,

            id: n.id,
        };
        return productInfo;
    });

    return (
        <div>
            <PageSection sectionTitle="Featured projects">
                <Flex column className={css(S.MobileProjectTileWrapper)}>
                    {projectTiles?.map((p) => {
                        return (
                            <ProjectTile
                                key={p.id}
                                className={css(S.ProjectTile)}
                                image={[
                                    {
                                        src: p?.images[0] ?? '',
                                        sizes: '100vw',
                                        alt: professional.Title,
                                    },
                                ]}
                                professionalName={'By ' + p.professionalName}
                                projectName={p.projectName}
                                variant="02"
                                size={'large'}
                                newDesignEnabled={true}
                                hideFavourite={disableSaveToDesignBoard}
                                onFavourite={(e) =>
                                    favouriteItem.onClick(e, p.id)
                                }
                                isFavourited={favouriteItem.favourited(p.id)}
                                topActions={[]}
                                as={renderTileAs}
                                to={disableTileLink ? undefined : p.projectLink}
                                {...(!disableTileLink && {
                                    prefetch: 'intent',
                                })}
                                target={openInNewTab ? '_blank' : '_self'}
                                impression={{
                                    type: 'LibraryItem_Directory',
                                    itemID: p.id,
                                    professionalID: professional.ID,
                                }}
                            />
                        );
                    })}
                    {projectCount && projectCount >= 3 && (
                        <Button
                            color="white"
                            outlined={true}
                            transparent={true}
                            size={20}
                            as={Link}
                            to={`${professional.BaseUrl}/case-studies`}
                        >
                            VIEW MORE
                        </Button>
                    )}
                </Flex>
            </PageSection>

            {!disableSaveToDesignBoard && favouriteItem.target && (
                <SaveToDesignBoard {...favouriteItem} />
            )}
        </div>
    );
};
