import {
    Button,
    Flex,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import { BusinessFocus } from './BusinessFocus';
import { ServicesWeProvide } from './ServicesWeProvide';
import * as S from './MainInformation.style';
import { ProjectsPublished } from './ProjectsPublished';
import { AboutDescription } from '../about-description/AboutDescription';
import { Divider } from './Divider';
import { Associations } from '../associations/Associations';
import type { LocationDataType } from '@modules/professional/type/contact-page-types';
import { Locations } from '../locations/Locations';
import { SocialLinks } from './SocialLinks';
import { YearFounded } from './YearFounded';
import { YearJoined } from './YearJoined';
import { ProfessionalPhoneNumbers } from './ProfessionalPhoneNumbers';
import { useCurrentTabBannerImage } from '~/modules/professional/hook/use-current-tab-banner-image';
import { useProfessionalInitialRouteData } from '~/modules/professional/hook/use-professional-initial-route-data';
import { ProductsListed } from './ProductsListed';
import { OverviewEnquiryForm } from './OverviewEnquiryForm';
import EnquiryModal from '~/modules/enquiry/component/enquiry-modal/EnquiryModal';
import { useTracker } from '@archipro-website/tracker';
import { trackEnquireReveal } from '~/modules/tracking/util/trackEnquireReveal';
import { ExternalLinkLine } from '@archipro-design/icons';
import BookProfessional from '@modules/professional/component/header-section/BookProfessional';
import { useAppData } from '@modules/root';
import Availability from './Availability';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import { useSaveToDesignBoard } from '@modules/design-board';

import cx from 'classnames';
import { useWebsiteReferralTracking } from '~/modules/tracking/util/websiteTracking';
import { PrimaryCtaEnum } from 'generated/graphql';

interface MainInformationProps {
    locations: LocationDataType[];
    responseRate: number;
    calculateDistance: () => Promise<boolean>;
    showProducts: boolean;
    disabledSocialLinks?: boolean;
    disabledSendEnquiry?: boolean;
    disableSaveToDesignBoard?: boolean;
}

export const MainInformation: React.FC<MainInformationProps> = ({
    locations,
    calculateDistance,
    showProducts,
    disabledSocialLinks,
    disabledSendEnquiry,
    disableSaveToDesignBoard,
}) => {
    const professional = useProfessionalInitialRouteData();
    const { Banners: banners } = professional;
    const bannerImage = useCurrentTabBannerImage(banners);
    const tracker = useTracker();
    const { mobile: isMobile } = useAppDisplayModeContext();
    const { css } = useStyles({
        hasBannerImage: !!bannerImage,
    });
    const { favouriteItem } = useSaveToDesignBoard(
        'ProfessionalPin',
        professional.ID
    );
    const { bookConsultationEnabled, availableForWorkEnabled } = useAppData();
    const showBookConsultation =
        professional.TeamCalendarURLSegment &&
        bookConsultationEnabled &&
        professional.primaryCTA?.primaryCTA === PrimaryCtaEnum.BookConsultation;
    const availableForWork =
        professional.AvailableForWork && availableForWorkEnabled;

    const {
        referralTrackingURL,
        referralTrackingClass,
        referralTrackingParams,
    } = useWebsiteReferralTracking(professional.Website, {
        libraryItemID: professional.ID,
        professionalTitle: professional.Title,
    });

    return isMobile ? (
        <div className={css(S.MobileInfoContainer)}>
            <AboutDescription />
            <Flex column className={css(S.Contacts)}>
                <div className={css(S.EnquireContainer)}>
                    {showBookConsultation ? (
                        <BookProfessional
                            professional={professional}
                            enquiryDrawer={true}
                            enquiryType={'professional'}
                        />
                    ) : (
                        <Flex>
                            <EnquiryModal
                                professional={professional}
                                branchOptions={professional.branchOptions}
                                trigger={
                                    <Button
                                        size={16}
                                        color="dark"
                                        className={css(S.EnquireButton)}
                                        onClick={() => {
                                            trackEnquireReveal(tracker, {
                                                professionalName:
                                                    professional.Title,
                                                itemID: professional.ID,
                                                itemType: 'professional',
                                                source: 'EnquiryModal',
                                            });
                                        }}
                                        variables={{
                                            darkButtonBackgroundColorNormal:
                                                '#222222',
                                            darkOutlinedButtonBorderNormal: `1px solid #222222`,
                                        }}
                                    >
                                        {professional.primaryCTA
                                            ?.primaryCTALabel ?? `SEND ENQUIRY`}
                                    </Button>
                                }
                                drawer={true}
                            />
                            <SaveToDesignBoard
                                heartButton={{
                                    className: css(S.ButtonHeart),
                                    color: 'primary',
                                    variables: {
                                        iconPadding: 0,
                                        buttonPadding: 0,
                                        primaryNoBKGButtonBackgroundColorHover:
                                            'transparent',
                                    },
                                    size: 20,
                                }}
                                {...favouriteItem}
                            />
                        </Flex>
                    )}
                </div>
                <Flex
                    gap="space-20"
                    className={css(S.SaveShareContainer)}
                    hAlign="center"
                    vAlign="start"
                >
                    <Button
                        as={'a'}
                        href={referralTrackingURL}
                        target="_blank"
                        size={16}
                        color="dark"
                        transparent
                        outlined
                        variables={{
                            buttonSize20LineHeight: '130%',
                            buttonPadding: pxToRem(15),
                        }}
                        className={cx(
                            css(S.ViewWebsiteButton),
                            referralTrackingClass
                        )}
                        {...referralTrackingParams}
                    >
                        VISIT WEBSITE
                        <ExternalLinkLine
                            styles={{
                                width: pxToRem(16),
                                height: pxToRem(16),
                                '& > svg': {
                                    marginLeft: pxToRem(8),
                                    marginTop: pxToRem(-2),
                                },
                            }}
                        />
                    </Button>
                </Flex>
            </Flex>

            <BusinessFocus />
            <ServicesWeProvide />
            {showProducts ? <ProductsListed /> : <ProjectsPublished />}

            <YearFounded />
            <YearJoined />

            <Associations />

            {locations.length > 0 && (
                <Locations
                    locations={locations}
                    calculateDistance={calculateDistance}
                    professionalID={professional.ID}
                />
            )}
            <ProfessionalPhoneNumbers />
            {availableForWork && <Availability />}

            <SocialLinks />
        </div>
    ) : (
        <div className={css(S.MainInfoContainer)}>
            <Flex space="between">
                <div className={css(S.LeftSection)}>
                    <BusinessFocus />
                    <ServicesWeProvide />
                    {showProducts ? <ProductsListed /> : <ProjectsPublished />}
                    <Divider />
                </div>
                <div className={css(S.RightSection)}>
                    <AboutDescription />
                    <YearFounded />
                    <YearJoined />
                    <Associations />
                    <SocialLinks disabledSocialLinks={disabledSocialLinks} />
                    <Locations
                        locations={locations}
                        calculateDistance={calculateDistance}
                        professionalID={professional.ID}
                    />
                    <ProfessionalPhoneNumbers />
                    {availableForWork && <Availability />}
                    <Divider />
                    <OverviewEnquiryForm
                        data={{
                            Professional: professional,
                        }}
                        disabledSendEnquiry={disabledSendEnquiry}
                        disableSaveToDesignBoard={disableSaveToDesignBoard}
                        showAddToDeisgnBoardInEnquiryForm={true}
                    />
                </div>
            </Flex>
        </div>
    );
};
